// import jsonServerProvider from 'ra-data-json-server';
import EventAvailableIcon from '@mui/icons-material/Event';
import KayakingIcon from '@mui/icons-material/Kayaking';
import PersonIcon from '@mui/icons-material/Person';
import jsonServerProvider from 'ra-data-json-server';
import { Admin, CustomRoutes, Resource, useListParams } from 'react-admin';
import { Route } from "react-router-dom";
import './App.css';
import { ItemCreate, ItemEdit, ItemList } from './items';
import { PartyEdit, PartyList } from './parties';
import { ReservationCreate, ReservationEdit, ReservationItemsAdd, ReservationList } from './reservations';
import LiesrlyTheme from './theme/liesrly';
import { AppConfig } from './appConfig';
import authProvider from './authProvider';


console.info(`Environment is: ${process.env.NODE_ENV}`);
// TODO: Add some blingy stuff to the page: https://marmelab.com/react-admin/Tutorial.html#customizing-the-page-title
if (process.env.NODE_ENV === 'development') {
  console.debug(`Using ${AppConfig.baseUrl} as base URL...`);
}
const dataProvider = jsonServerProvider(AppConfig.baseUrl);

function App() {
  return (
    <Admin authProvider={authProvider} theme={LiesrlyTheme} title="leisrly.cloud" dataProvider={dataProvider}>
      <Resource name="Point of Sale" icon={PersonIcon} options={{ label: "Point of Sale" }} list={PartyList} edit={PartyEdit} />
      <Resource name="reservations" icon={EventAvailableIcon} list={ReservationList} edit={ReservationEdit} create={ReservationCreate} />
      <Resource name="assets" icon={KayakingIcon} options={{ label: "Inventory" }} list={ItemList} edit={ItemEdit} create={ItemCreate} />
      <Resource name="parties" icon={PersonIcon} options={{ label: "Customers" }} list={PartyList} edit={PartyEdit} />
      <Resource name="packages" icon={PersonIcon} options={{ label: "Packages" }} list={PartyList} edit={PartyEdit} />
      <Resource name="discouts" icon={PersonIcon} options={{ label: "Discounts" }} list={PartyList} edit={PartyEdit} />
      { /* <Resource name="settings" icon={SettingsIcon} list={ListGuesser} /> */}
      { /* TODO: Check out https://reactrouter.com/en/6.6.1/route/route, I think we want to pass ${params.id} to the element... */}
      <CustomRoutes>
        <Route path="addReservationItems/:id" element={<ReservationItemsAdd />} />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
