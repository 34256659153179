import { defaultTheme } from 'react-admin';

/*
 * See https://marmelab.com/react-admin/Theming.html#global-theme-overrides, defining
 * a custom theme here for leisrly web app.
 * 
 * Got font from here: https://fonts.google.com/specimen/Montserrat
 * 
 * Used Material theme creater to create the code below: https://bareynol.github.io/mui-theme-creator/#Accordion
 * 
 * See theming: https://mui.com/material-ui/customization/theming/#createtheme-options-args-theme for more
 * information.
 * 
 * Overview of the MUI system: https://mui.com/system/getting-started/overview/#the-sx-prop
 * 
 * More information about react-admin thememing: https://marmelab.com/react-admin/Theming.html#global-theme-overrides
 * 
 * And even more: https://github.com/marmelab/react-admin/blob/master/docs/Theming.md
 */

const LiesrlyTheme = {
    ...defaultTheme,
    palette: {
        type: 'light',
        primary: {
            main: '#002128',
            light: '#2a4950',
            dark: '#000000',
            contrastText: '#fff',
        },
        secondary: {
            light: '#edffff',
            main: '#badcda',
            dark: '#8aaaa9',
            contrastText: '#000',
        },
    },
    components: {
        ...defaultTheme.components,
        RaLogin: {
            styleOverrides: {
                root: {
                    backgroundColor: "#002128",
                    backgroundImage: "none",
                }
            }
        }
    }
};

export default LiesrlyTheme;