import { AuthProvider } from 'react-admin';
import Amplify, { Auth } from "aws-amplify";
import { AwsConfigAuth } from "./config/auth";

Amplify.configure({ Auth: AwsConfigAuth });

const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        try {
            const result = await Auth.signIn(username, password);
            localStorage.setItem('username', result.getUsername());
            localStorage.setItem('isAuthenticated', 'true');

            Auth.currentSession().then(sess => {
                const jwt = sess.getAccessToken().getJwtToken();
                console.log(jwt);
                localStorage.setItem('jwt', jwt);
            });

            return Promise.resolve();
        } catch (error) {
            console.error(error);
        }
        return Promise.reject();
    },
    logout: async () => {
        try {
            await Auth.signOut();
            localStorage.removeItem('username');
            localStorage.removeItem('jwt');
            localStorage.setItem('isAuthenticated', 'false');
            return Promise.resolve();
        } catch (error) {
            console.error(error);
        }
        return Promise.reject();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem('isAuthenticated') && localStorage.getItem('isAuthenticated') === 'true' ? Promise.resolve() : Promise.reject(),
        // localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => Promise.reject('Unknown method'),
    getIdentity: () => {
        const fullName = localStorage.getItem('username') || '';
        return Promise.resolve({
            id: 'user',
            fullName: fullName
        })
    },
};

export default authProvider;