import { Datagrid, List, TextField } from 'react-admin';
import { Create, Edit, SimpleForm, TextInput } from 'react-admin';

export const ItemList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="type.name" label="Type" />
        </Datagrid>
    </List>
);

export const ItemEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description" />
            <TextInput source="type" />
        </SimpleForm>
    </Edit>
);

export const ItemCreate = () => (
    <Create>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description" />
            <TextInput source="type" />
        </SimpleForm>
    </Create>
);