import IconEvent from '@mui/icons-material/Event';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Card, CardContent } from '@mui/material';
import { Button, Create, Datagrid, DateField, DateInput, DeleteButton, Edit, EditButton, FilterList, FilterListItem, FilterLiveSearch, FormTab, List, Loading, NumberField, NumberInput, ReferenceManyField, SaveButton, SavedQueriesList, TabbedForm, TextField, TextInput, Toolbar, TopToolbar, useGetList, useListContext, useNotify, useRecordContext, useRedirect, useUnselectAll, useUpdateMany } from 'react-admin';
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';
import { AppConfig } from './appConfig';

export const ReservationList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="confirmationNumber" />
            <TextField source="party.partyFullName" label="Party Name" />
            <DateField source="planStartDate" />
            <DateField source="planEndDate" />
            <DateField source="createdDate" />
            <NumberField source="cost" />
        </Datagrid>
    </List>
);

const ReservationTitle = () => {
    const record = useRecordContext();
    return <span>{record ? `Reservation ${record.confirmationNumber} - ${record.party.partyFullName}` : ''}</span>;
};

export const ReservationToolbar = () => (
    <Toolbar>
        <EditButton label="Add Items..." resource='addReservationItems' />
        <SaveButton label="Save" />
        <DeleteButton label="Delete" />
    </Toolbar>
);

export const ReservationEdit = () => (
    <Edit title={<ReservationTitle />}>
        <TabbedForm toolbar={<ReservationToolbar />}>
            <FormTab label="summary">
                <TextField source="confirmationNumber" label="Confirmation Number" />
                <TextInput source="party.partyFullName" label="Name" />
                <DateInput source="planStartDate" label="Start Date" />
                <DateInput source="planEndDate" label="End Date" />
                <DateInput source="createdDate" label="Date Created" />
                <NumberInput source="cost" />
                <ReferenceManyField reference="reservationItems" target="reservationId" label="Reserved Items">
                    <Datagrid>
                        <TextField source="item.type" label="Type" />
                        <TextField source="item.name" label="Make" />
                        <TextField source="item.description" label="Model" />
                        <TextField source="item.assetTag" label="Tag/Serial no." />
                        <DateInput source="planStartDate" label="Start" />
                        <DateInput source="planEndDate" label="End" />
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="leaders">
                {/* TODO: Put history here. */}
            </FormTab>
            <FormTab label="lodging">
                {/* TODO: Put history here. */}
            </FormTab>
            <FormTab label="transportation">
                {/* TODO: Put history here. */}
            </FormTab>
            <FormTab label="miscelaneous">
                {/* TODO: Put history here. */}
            </FormTab>
            <FormTab label="history">
                {/* TODO: Put history here. */}
            </FormTab>
        </TabbedForm>
    </Edit>
);

export const ReservationCreate = () => (
    <Create title={<ReservationTitle />}>
        <TabbedForm>
            <FormTab label="summary">
                <TextInput source="confirmationNumber" />
                <TextInput source="party.partyFullName" label="Name" />
                <DateInput source="planStartDate" label="Start Date" />
                <DateInput source="planEndDate" label="End Date" />
                <DateInput source="createdDate" label="Date Created" />
                <NumberInput source="cost" />
            </FormTab>
            <FormTab label="leaders">
                {/* TODO: Put history here. */}
            </FormTab>
            <FormTab label="lodging">
                {/* TODO: Put history here. */}
            </FormTab>
            <FormTab label="transportation">
                {/* TODO: Put history here. */}
            </FormTab>
            <FormTab label="history">
                {/* TODO: Put history here. */}
            </FormTab>
        </TabbedForm>
    </Create>
);

const ReservationItemsTypeFilterList = () => {
    const { isLoading, error, data } = useQuery('typeFilter', () =>
        fetch(`${AppConfig.baseUrl}/meta/filters/inventoryTypes`).then(res =>
            res.json()
        )
    );
    console.debug(JSON.stringify(data));
    if (isLoading) {
        return <Loading />
    }

    if (error) {
        return (<div></div>);
    }

    return <FilterList label="Type" icon={<FilterListIcon />}>
        {
            data.map((element: string) => (
                <FilterListItem label={`${element}`} value={{ type: element }} />
            ))
        }
    </FilterList>;
}

const ReservationItemsBrandFilterList = () => {
    const { isLoading, error, data } = useQuery('brandFilter', () =>
        fetch(`${AppConfig.baseUrl}/meta/filters/inventoryBrands`).then(res =>
            res.json()
        )
    );
    console.debug(JSON.stringify(data));
    if (isLoading) {
        return <Loading />
    }

    if (error) {
        return (<div></div>);
    }

    return <FilterList label="Brand" icon={<FilterListIcon />}>
        {
            data.map((element: string) => (
                <FilterListItem label={`${element}`} value={{ brand: element }} />
            ))
        }
    </FilterList>;
}


const ReservationItemsFilterSidebar = () => {


    return <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
        <CardContent>
            <SavedQueriesList />
            <FilterLiveSearch source="searchText" />
            <ReservationItemsTypeFilterList />
            <ReservationItemsBrandFilterList />
        </CardContent>
    </Card>
};

const ReservationItemsListActions = ({reservationId}: {reservationId: number}) => {

    const { selectedIds } = useListContext();
    const [updateMany, { isLoading, error }] = useUpdateMany(
        `reservation/${reservationId}/addItem`,
        { ids: selectedIds, data: {} }
    );
    // const navigator = useNavigate();
    // const handleClick = () => {
    //     updateMany().then(() => {
    //         navigator(`/reservations/${reservationId}`);
    //     }
    //     );
    // }

    const notify = useNotify();
    const redirect = useRedirect();
    const unselectAll = useUnselectAll(`availabilities/${reservationId}`);
    const onSuccess = () => {
        unselectAll();
        notify("Items added to reservation");
        redirect(`/reservations/${reservationId}`);
    }

    const handleClick = () => {
        updateMany().then(onSuccess);
    } 

    return <TopToolbar>
        {/* See https://marmelab.com/react-admin/useListContext.html and
        https://marmelab.com/react-admin/Actions.html#usequery-and-usemutation */}
        <Button onClick={handleClick} label="Add items..." >
            <IconEvent />
        </Button>
    </TopToolbar>
};

const ReservationItemsAddTitle = () => {
    // TODO: This does not resolve to anything yet.
    // const record = useRecordContext();
    // console.debug("Record is: " + JSON.stringify(record));
    return <span>{"Add items to reservation"}</span>;
}

export const ReservationItemsAdd = () => {
    const location = useLocation();
    const parts = location.pathname.split('/');
    const reservationId = Number(parts[parts.length - 1]);
    console.debug(`id is: ${reservationId}`);

    return <Card>
        <List title={<ReservationItemsAddTitle />} resource={`availabilities/${reservationId}`}
            filter={{ reservation_id: 1 }} aside={<ReservationItemsFilterSidebar />}>
            <Datagrid bulkActionButtons={<ReservationItemsListActions reservationId={reservationId} />}>
                <TextField source="type" label="Type" />
                <TextField source="name" label="Brand" />
                <TextField source="description" label="Model" />
                <TextField source="assetTag" label="Tag/Serial no." />
            </Datagrid>
        </List>
    </Card>;
};