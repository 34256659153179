import { ArrayInput, BooleanField, BooleanInput, Create, Datagrid, Edit, EmailField, List, SelectInput, SimpleForm, SimpleFormIterator, TextField, TextInput, useRecordContext } from 'react-admin';

const PostTitle = () => {
    const record = useRecordContext();
    return <span>{record ? `${record.partyFullName}` : ''}</span>;
};

export const PartyList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="partyFullName" />
            <EmailField source="partyInetAddresses[0].inetAddress" label="Email" sortable={false} />
            <TextField source="partyAddresses[0].line1" label="Address" sortable={false} />
            <TextField source="partyAddresses[0].city" label="City" sortable={false} />
            <TextField source="partyAddresses[0].state" label="State" sortable={true} sortBy="partyAddresses.state" />
            <BooleanField source="isGroup" label="Group" />
            <BooleanField source="isNonProfit" label="Non Profit" />
        </Datagrid>
    </List>
);


export const PartyEdit = () => (
    <Edit title={<PostTitle />}>
        <SimpleForm>
            <TextInput source="partyFullName" />
            <BooleanInput source="isGroup" />
            <BooleanInput source="isNonProfit" />
            <ArrayInput source="partyAddresses" label="Addresses">
                <SimpleFormIterator inline disableReordering getItemLabel={index => ``} fullWidth disableClear>
                    <SelectInput source="addressType" label="Type" choices={[
                        { id: 'billing', name: 'Billing' },
                        { id: 'mailing', name: 'Mailing' },
                    ]}
                    />
                    <TextInput source="line1" label="Address Line 1" />
                    <TextInput source="city" />
                    <TextInput source="state" />
                    <TextInput source="postalCode" />
                    <TextInput source="country" />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="partyInetAddresses" label="Email, Web and Social Media Addresses">
                <SimpleFormIterator inline disableReordering getItemLabel={index => ``} fullWidth disableClear>
                    <SelectInput source="inetAddressType" label="Type" choices={[
                        { id: 'email', name: 'email' },
                        { id: 'instagram', name: 'Instagram' },
                        { id: 'facebook', name: 'Facebook' },
                        { id: 'web', name: 'Web site' },
                    ]}
                    />
                    <TextInput source="inetAddress" label="Address" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);